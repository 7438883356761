<template>
    <ModalMessage v-if="!!error" :message="messageError" :isSuccess="false" @close="closeModal" />
    <Student v-else-if="userRole === 'Обучаемый'" />
    <Customer v-else-if="userRole === 'Заказчик'" />
</template>

<script>
import Student from "./student/Personal"
import Customer from "./customer/Personal"
import ModalMessage from "@/components/modals/modal-message"
import store from "@/store/index.js"

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch("PERSONAL_GET_FIELDS").finally(() => {
            next();
        });
    },

    components: {
        Student,
        Customer,
        ModalMessage,
    },

    computed: {
        userRole() {
            return this.$store.state.personal.user ? this.$store.state.personal.user.role : ''
        },
        error() {
            return this.$store.state.personal.errors;
        },
        messageError() {
            return {
                title: this.error,
                description: 'Для получения информации свяжитесь с менеджером по телефону 8-800-707-55-19'
            }
        }
    },

    methods: {
        closeModal() {
            this.$store.dispatch('AUTH_LOGOUT')
                .then(() => {
                    document.querySelector(".js-body").classList.remove("menu-active")
                    this.$router.push('/login')
                })
        }
    }
}
</script>
