<template>
    <ProfileInfo />

    <template v-if="fields.personal">
        <div class="section-info__row">
            <div class="subtitle">{{ fields.personal.title }}</div>
            <div class="remark">
                <svg class="icon icon-Warning ">
                    <use xlink:href="#Warning"></use></svg
                >Требует проверки
            </div>
        </div>
        <div class="section-info__description">
            Если данные не заполнены или заполнены неверно документ об
            образовании будет недействителен
        </div>
        <div class="form-default">
            <div class="form-default__row">
                <template v-for="input in fields.personal.fields" :key="input.id">
                    <InputFile
                        v-if="input.type === 'file'"
                        :label="input.name"
                        :code="input.code"
                        :modelValue="input.value"
                        :tooltip="input.tooltip"
                        :is-required="input.required"
                        @update:modelValue="onSaveFile($event, input.code)"
                    />
                    <InputDate
                        v-if="input.type === 'date'"
                        :label="input.name"
                        :code="input.code"
                        :modelValue="input.value"
                        :is-required="input.required"
                        @update:modelValue="request[input.code] = $event"
                     />
                    <Input
                        v-if="input.type !== 'date' && input.type !== 'file'"
                        :type="input.type"
                        :label="input.name"
                        :code="input.code"
                        :tooltip="input.tooltip"
                        :modelValue="input.value"
                        @update:modelValue="request[input.code] = $event"
                    />
                </template>

            </div>

            <div class="form-default__submit">
                <button class="btn btn-white" @click.prevent="saveData">
                    Сохранить
                </button>
                <div class="text-gray">
                    Нажав на кнопку, вы не сможете отредактировать поля с
                    информацией
                </div>
            </div>
        </div>
    </template>
    <div class="section-info__row" v-else>
        <div class="subtitle">Все Ваши данные заполнены.</div>
    </div>
</template>

<script>
import ProfileInfo from "@/pages/personal/components/profile-info";
import Input from "@/components/form/input";
import InputFile from '@/components/form/input-file'
import InputDate from '@/components/form/input-date'

export default {
    components: {
        ProfileInfo,
        Input,
        InputFile,
        InputDate
    },

    data() {
        return {
            request: {}
        };
    },

    computed: {
        fields() {
            return this.$store.state.personal.fields;
        },
    },

    methods: {
        saveData() {
            Object.entries(this.request).map(a =>
                Object.entries(a[1]).filter(b => b[1].length).length
                    ? a
                    : delete this.request[a[0]]
            );
            if (this.request)
                this.$store.dispatch("PERSONAL_SAVE_DATA", this.request).then(() => this.request = {})
        },
        onSaveFile(file, code) {
            this.$store.dispatch('PERSONAL_SET_CERTIFICATE', file)
                .then(response => this.request[code] = response.data)
        }
    }
};
</script>
