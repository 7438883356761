<template>
    <BaseModal :classes="isSuccess ? 'modal--success' : 'modal--error'">
        <div class="modal__icon">
            <svg class="icon icon-Smiley" v-if="isSuccess">
                <use xlink:href="#Smiley"></use>
            </svg>
            <svg class="icon icon-smiley-sad-1" v-else>
                <use xlink:href="#smiley-sad-1"></use>
            </svg>
        </div>
        <div class="modal__title subtitle js-success-modal-title" v-if="message.title">
            {{ message.title }}
        </div>
        <div class="modal__description js-success-modal-description" v-if="message.description">
            {{ message.description }}
        </div>
    </BaseModal>
</template>

<script>
import BaseModal from "@/components/modals/base-modal";

export default {
    components: {
        BaseModal,
    },

    props: {
        isSuccess: Boolean,
        message: Object,
    }
}
</script>
