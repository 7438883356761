<template>
    <ProfileInfo />

    <form class="form" @submit.prevent="onSubmit">

            <FieldsList :groupFields="groupFields" :errors="errors" @update:fields="getFields" />
        <div class="wrapper">
            <button type="submit" class="btn btn-white">
                Сохранить
            </button>
            <div class="text-big" v-if="isSendRequest">Изменения сохранены.</div>
        </div>
    </form>
</template>

<script>
import ProfileInfo from "@/pages/personal/components/profile-info";
import FieldsList from '@/components/form/fields'

export default {
    components: {
        ProfileInfo,
        FieldsList,
    },

    data() {
        return {
            fields: {},
            errors: {},
            isSendRequest: false,
        };
    },

    computed: {
        groupFields() {
            return this.$store.state.personal.fields;
        }
    },

    methods: {
        onSubmit() {
            Object.entries(this.fields).map(a =>
                Object.entries(a[1]).filter(b => b[1].length).length
                    ? a
                    : delete this.fields[a[0]]
            );
            this.$store.dispatch("PERSONAL_SAVE_DATA", this.fields)
            .then(() => {
                this.fields = {}
                this.isSendRequest = true
            })
            .catch(error => {
                this.isSendRequest = false
                if (error.response.data.errors.email) this.errors = error.response.data.errors
            })
        },

        getFields(payload) {
            this.fields = payload
        }
    }
};
</script>
