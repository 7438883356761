<template>
    <div class="profile-info">
        <div class="profile-info__img" v-if="user.avatar">
            <img :src="user.avatar" />
        </div>

        <div class="profile-info__img" v-else >
            <img src="/img/icons/avatar-student.svg" v-if="user.type === 'Обучаемый'" />
            <img src="/img/icons/avatar-customer.svg" v-else />
        </div>

        <div class="profile-info__text">
            <div class="profile-info__name subtitle">{{ user.name }}</div>

            <input type="file" v-show="false" name="file" ref="input" @change="changeAvatar" />

            <button class="btn btn-download" @click="openFileInput" v-if="!user.avatar">
                <svg class="icon icon-pencil-1 ">
                    <use xlink:href="#pencil-1"></use>
                </svg>
                Изменить изображение
            </button>
            <button class="btn btn-download" @click="removeFile" v-else>
                <svg class="icon icon-pencil-1 ">
                    <use xlink:href="#pencil-1"></use>
                </svg>
                Удалить изображение
            </button>
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        user() {
            return this.$store.state.personal.user
        }
    },

    methods: {
        openFileInput() {
            this.$refs.input.click()
        },
        changeAvatar(event) {
            const formData = new FormData()
            formData.append('avatar', event.target.files[0])

            this.$store.dispatch("PERSONAL_UPDATE_AVATAR", formData)
                .then(() => this.$refs.input.value = '')
        },
        removeFile() {
            this.$store.dispatch("PERSONAL_DELETE_AVATAR")
        }
    }
}
</script>
