<template>
        <div
            class="form-big"
            v-for="(form, index) in mainFields"
            :key="index"
        >
            <p class="subtitle" v-if="form.title">{{ form.title }}</p>

            <div class="form-big__inputs">
                <div
                    v-for="(input, index) in form.fields"
                    :key="index"
                    class="form-big__input"
                    :class="`form-big__input--${input.size_attr} form-big__input--${input.code}`"
                >
                    <InputFile
                        v-if="input.type === 'file'"
                        :label="input.name"
                        :code="input.code"
                        :modelValue="input.value"
                        :is-required="input.required"
                        :error="errors[input.code]"
                        @update:modelValue="onSaveFile($event, input.code)"
                     />
                     <InputDate
                        v-if="input.type === 'date'"
                        :label="input.name"
                        :code="input.code"
                        :modelValue="input.value"
                        :error="errors[input.code]"
                        @update:modelValue="fields[input.code] = $event"
                     />
                    <Input
                        v-if="input.type !== 'date' && input.type !== 'file'"
                        :type="input.type"
                        :label="input.name"
                        :code="input.code"
                        :error="errors[input.code]"
                        :is-required="input.required"
                        :modelValue="input.value"
                        :newValue="fields[input.code]"
                        :maxLength="input.symbols_count"
                        :placeholder="input.placeholder"
                        @update:modelValue="fields[input.code] = $event"
                    >
                        <template
                            v-slot:addition
                            v-if="input.dependent_field && dependentFields.hasOwnProperty(input.dependent_field)"
                        >
                            <label class="checkbox">
                                <input
                                    :name="dependentFields[input.dependent_field].code"
                                    type="checkbox"
                                    v-model="fields[dependentFields[input.dependent_field].code]"
                                    @change="isDependentField($event)"
                                >
                                <span class="checkbox__indicator">
                                    <svg class="icon icon-check ">
                                        <use xlink:href="#check"></use>
                                    </svg>
                                </span>
                                <span class="checkbox__description">{{ dependentFields[input.dependent_field].name }}</span>
                            </label>
                        </template>
                    </Input>
                </div>
            </div>
        </div>
</template>

<script>
import Input from '@/components/form/input'
import InputFile from '@/components/form/input-file'
import InputDate from '@/components/form/input-date'

export default {
    name: "Fields",

    components: {
        Input,
        InputFile,
        InputDate
    },

    props: {
        groupFields: Object,
        errors: Object,
    },

    data() {
        return {
            fields: {},
        };
    },
    watch: {
        fields: {
            deep: true,
            immediate: true,
            handler() {
                this.deleteError()
                this.$emit('update:fields', this.fields)
            },
        },
    },

    computed: {
        mainFields() {
            return Object.values(this.groupFields).map(item => {
                const group = {... item}

                const dependents = group.fields.map(field => field.dependent_field).filter(field => field)
                const mainFileds = group.fields.filter(field => !dependents.includes(field.code))

                group.fields = mainFileds
                return group
            })
        },

        dependentFields() {
            const fields = {}

            Object.values(this.groupFields).forEach(group => {
                const dependents = group.fields.map(field => field.dependent_field).filter(field => field)
                group.fields.filter(field => dependents.includes(field.code)).forEach(field => fields[field.code] = field)
            })

            return fields
        }
    },
    methods: {
        isDependentField(event) {
            if (event.target.checked) {
                this.fields.address ?
                this.fields.mailing_address = this.fields.address :
                this.fields.address = this.fields.mailing_address
            } else delete this.fields.mailing_address
        },
        deleteError() {
            const keysFields = Object.keys(this.fields)
            const keysErrors = Object.keys(this.errors)
            keysErrors.forEach(item => {
                if (keysFields.find(el => item === el)) {
                    if (this.fields[item]) delete this.errors[item]
                }
            })
        },
        onSaveFile(file, code) {
            this.$store.dispatch('PERSONAL_SET_CERTIFICATE', file)
                .then(response => this.fields[code] = response.data)
        }
    }
}
</script>
